import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import PrivacyBar from "../components/privacyBar"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import ContactForm from "./ContactForm"
import { event } from "jquery"
import animationImg from "../img/helpdesk-2.gif"
const Footer = props => {
  const { pageType } = props
  const { meetingClass } = props
  const data = useStaticQuery(graphql`
    query FooterQuery {
      limitedCity: allCities(limit: 20) {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }

      allCities: allCities(sort: { fields: city, order: ASC }) {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
      datesBuild: allCurrentBuildDate {
        edges {
          node {
            currentDate
          }
        }
      }
    }
  `)

  const {
    utm_source,
    utm_content,
    utm_medium,
    utm_campaign,
    utm_term,
  } = props.search

  // Function to check if a value is not empty
  const isNotEmpty = value =>
    value !== undefined && value !== null && value.trim() !== ""

  let timestampStored = false
  // Store in localStorage if not empty
  if (isNotEmpty(utm_source)) {
    localStorage.setItem("utm_source", utm_source)
    timestampStored = true
  }

  if (isNotEmpty(utm_content)) {
    localStorage.setItem("utm_content", utm_content)
    timestampStored = true
  }

  if (isNotEmpty(utm_medium)) {
    localStorage.setItem("utm_medium", utm_medium)
    timestampStored = true
  }

  if (isNotEmpty(utm_campaign)) {
    localStorage.setItem("utm_campaign", utm_campaign)
  }

  if (isNotEmpty(utm_term)) {
    localStorage.setItem("utm_term", utm_term)
  }
  if (timestampStored) {
    const currentTimestamp = Date.now()
    localStorage.setItem("utm_timestamp", currentTimestamp)
  }
  const { locality } = props.search
  const { purpose } = props.search
  const [floatingAction, setfloatingAction] = useState(false)
  const [modalView, setmodalView] = useState(false)
  const [intentmodalView, setintentmodalView] = useState(false)
  const [citymodalView, setcitymodalView] = useState(false)
  const [cities, setcities] = useState([""])
  const [spaceType, setspaceType] = useState("coworking-spaces")
  const [resetLocation, setResetLocation] = useState(false)
  const floatingActionChange = () => {
    if (floatingAction === true) {
      setfloatingAction(false)
    } else {
      setfloatingAction(true)
    }
  }
  useEffect(() => {
    setResetLocation(localStorage.getItem("resetLocation"))
    console.log("setResetLocation", resetLocation)
  }, [resetLocation])

  const closeModal = () => {
    if (modalView === true) {
      setmodalView(false)
      localStorage.setItem("newpopupclose", true)
      localStorage.setItem(
        "newpopupclosecount",
        Number(localStorage.getItem("newpopupclosecount")) + 1
      )
    } else {
      setmodalView(true)
      localStorage.setItem("newpopupclose", true)
    }
  }
  const closeCityModal = () => {
    setcitymodalView(false)
  }
  const changeSlug = slug => {
    setcitymodalView(false)
    localStorage.setItem("slug", slug)
    localStorage.setItem("resetLocation", false)
    navigate("/" + spaceType + "/" + slug + "/all")
  }
  useEffect(() => {
    var citySlugGet = window.location.pathname.split("/")
    if (citySlugGet.includes("all") && !localStorage.getItem("resetLocation")) {
      localStorage.setItem("slug", citySlugGet[2])
    }

    if (localStorage.getItem("slug") || pageType !== "city") {
      setcitymodalView(false)
    } else {
      setcitymodalView(true)
    }
  }, [])

  const handleScroll = () => {
    let scrollheight =
      ((window.scrollY + window.innerHeight) / document.body.scrollHeight) * 100

    if (scrollheight >= 25 && pageType == "city") {
      if (Number(localStorage.getItem("newpopupclosecount")) < 1) {
        setmodalView(true)
      } else {
        setmodalView(false)
      }
    }
  }
  const mouseOut = e => {
    let x = e.clientX
    let y = e.clientY
    console.log("y", y)
    if (y > 20) {
      setintentmodalView(true)
      setmodalView(false)
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }
  const intentcloseModal = () => {
    setintentmodalView(!intentmodalView)
    if (intentmodalView) {
      setmodalView(false)
    }
  }

  useEffect(() => {
    if (locality) {
      localStorage.setItem("locality", locality)

      const resetLocation = localStorage.getItem("resetLocation")

      if (resetLocation) {
        if (purpose && purpose.length > 0) {
          localStorage.setItem("filterPurpose", purpose)
          setcitymodalView(false)
        } else {
          setcitymodalView(true)
        }
      } else {
        setcitymodalView(false)
      }
    } else {
      localStorage.removeItem("locality")
    }

    // Ensure to handle spaceType state setting outside the locality check
    if (localStorage.getItem("spaceType")) {
      setspaceType(localStorage.getItem("spaceType"))
    }
  }, [locality]) // Dependency array includes locality and purpose

  const topCity = [
    {
      slug: "bengaluru",
      cityname: "Bangalore",
    },
    {
      slug: "chennai",
      cityname: "Chennai",
    },
    {
      slug: "mumbai",
      cityname: "Mumbai",
    },
    {
      slug: "new-delhi",
      cityname: "New Delhi",
    },
    {
      slug: "noida",
      cityname: "Noida",
    },
    {
      slug: "gurugram",
      cityname: "Gurugram",
    },
    {
      slug: "hyderabad",
      cityname: "Hyderabad",
    },
    {
      slug: "pune",
      cityname: "Pune",
    },
    {
      slug: "kolkata",
      cityname: "Kolkata",
    },
  ]

  const CityList = ({ data }) => (
    <ul className="links row">
      {data.allCities.edges.map(city => {
        if (city.node.slug) {
          return (
            <li className="col-md-6 noleft">
              <Link to={"/" + city.node.slug + "/"}>
                {city.node.displayCity}
              </Link>
            </li>
          )
        }
      })}
    </ul>
  )

  const FamousLocality = ({ data }) =>
    data.map(city => {
      if (city.slug) {
        return (
          <div
            className="col-md-1 col-4 city-icons"
            onClick={() => {
              changeSlug(city.slug)
            }}
          >
            <p>
              <img
                src={
                  "https://assets.gofloaters.com/coworking/" +
                  city.slug +
                  ".svg"
                }
                alt={city.cityname}
              ></img>
              <br></br>
              {city.cityname}
            </p>
          </div>
        )
      }
    })
  const topCityList = [
    "Bangalore",
    "Chennai",
    "Mumbai",
    "New Delhi",
    "Noida",
    "Gurugram",
    "Hyderabad",
    "Pune",
    "Kolkata",
  ]

  const OtherLocality = ({ data }) =>
    data.allCities.edges.map(city => {
      if (city.node.slug) {
        if (!topCityList.includes(city.node.displayCity))
          return (
            <div
              className="col-md-2 col-4 city-icons-box"
              onClick={() => {
                changeSlug(city.node.slug)
              }}
            >
              <p>{city.node.displayCity}</p>
            </div>
          )
      }
    })
  const CoworkingCityList = ({ data }) =>
    data.limitedCity.edges.map(city => {
      if (city.node.slug) {
        return (
          <span>
            <Link to={"/coworking-spaces/" + city.node.slug + "/all"}>
              Coworking space in {city.node.displayCity}
            </Link>{" "}
            ･{" "}
          </span>
        )
      }
    })
  const MeetingCityList = ({ data }) =>
    data.limitedCity.edges.map(city => {
      if (city.node.slug) {
        return (
          <span>
            <Link to={"/meeting-spaces/" + city.node.slug + "/all"}>
              Meeting space in {city.node.displayCity}
            </Link>{" "}
            ･{" "}
          </span>
        )
      }
    })
  const OfficeCityList = ({ data }) =>
    data.limitedCity.edges.map(city => {
      if (city.node.slug) {
        return (
          <span>
            <Link to={"/office-spaces/" + city.node.slug + "/all"}>
              Office space in {city.node.displayCity}
            </Link>{" "}
            ･{" "}
          </span>
        )
      }
    })
  return (
    <footer>
      <div className="overlayIntent" onMouseMove={mouseOut}></div>
      <div className={"container " + meetingClass}>
        <div className="row">
          <div className="col-md-12 text-center">
            <br></br>
            <img
              data-src="https://assets.gofloaters.com/logo.png"
              className="lazyload"
              width="230"
              height="65"
              alt="GoFloaters"
            ></img>
            <br />
            <br />
            <p style={{ fontSize: 19 }}>
              <b>GoFloaters</b> is a platform that helps you find work and
              meeting spaces that can be booked instantly.
            </p>
            <p style={{ fontSize: 19 }}>
              A unit of <b>SMBSure Business Solutions Private Limited</b> <br />{" "}
              Millenia Business Park Campus - 1A, 2nd Floor, 9/1A MGR Main Road,
              Perungudi, Chennai, Tamil Nadu, 600096 India
            </p>
            <br></br>
            <br></br> <br></br>
          </div>
          <div className="col-md-4 text-left">
            <b style={{ fontSize: 18 }}>Quick Links</b>

            <div className="container">
              <ul className="links row">
                <li className="col-md-6 noleft">
                  <Link to="/about-us/"> About Us </Link>
                </li>
                <li className="col-md-6 noleft">
                  <Link to="/how-are-we-different/">Why GoFloaters </Link>
                </li>
                <li className="col-md-6 noleft">
                  <a
                    href="https://spaceadmin.gofloaters.com/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    Space Partner Login
                  </a>
                </li>

                <li className="col-md-6 noleft">
                  <Link to="/suggest-space/"> Suggest A Space </Link>
                </li>
                <li className="col-md-6 noleft">
                  <a
                    href="https://virtualoffice.gofloaters.com/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    Virtual Offices
                  </a>
                </li>

                <li className="col-md-6 noleft">
                  <Link to="/contact-us/">Contact Us</Link>
                </li>
                <li className="col-md-6 noleft">
                  <Link to="/faqs/"> FAQs </Link>
                </li>

                <li className="col-md-6 noleft">
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li className="col-md-6 noleft">
                  <Link to="/terms-and-conditions/">Terms & Conditions</Link>
                </li>
                <li className="col-md-6 noleft">
                  <Link to="/worqflexi/" className="teamLinksNav">
                    {" "}
                    WorqFlexi <span>New</span>
                  </Link>
                </li>
                {/* <li className="col-md-6 noleft">
                  <Link to="/passes/">GoFloaters Passes</Link>
                </li> */}
                <li className="col-md-6 noleft">
                  <Link to="/dont-let-space-go-to-waste/">List your Space</Link>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="col-md-4 text-left">
            <b style={{ fontSize: 18 }}>Cities</b>
            <div className="container">
              <StaticQuery
                query={graphql`
                  query {
                    allCities {
                      edges {
                        node {
                          city
                          cityPriority
                          displayCity
                          slug
                        }
                      }
                    }
                  }
                `}
                render={data => <CityList data={data} {...props} />}
              />
            </div>
          </div> */}

          <div className="col-md-4">
            <b
              className="contact-us-p"
              style={{
                fontSize: "18px",
                textAlign: "left",
              }}
            >
              Contact Us
            </b>
            <ul className="links">
              <li>
                Email:{" "}
                <a href="mailto:support@gofloaters.com">
                  support@gofloaters.com
                </a>
              </li>
            </ul>
            <ul className="sociallinks">
              <li>
                <a
                  href="https://www.facebook.com/gofloatersindia"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="https://assets.gofloaters.com/facebook.png"
                    width="30"
                    height="30"
                    alt="GoFloaters Facebook"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/gofloatersapp"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    data-src="https://assets.gofloaters.com/instagram.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters Instagram"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/gofloaters/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    data-src="https://assets.gofloaters.com/linkedin.png"
                    width="30"
                    height="30"
                    className="lazyload"
                    alt="GoFloaters linkedin"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/gofloaters"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <img
                    data-src="https://assets.gofloaters.com/twitter.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters Twitter"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCy_PdgFKsHuZY_hZ2r8gQvg"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <img
                    data-src="https://assets.gofloaters.com/youtube.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters YouTube"
                  ></img>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            {/* <p>
              <a
                href="https://bit.ly/GoFloatersPlayStore"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img
                  data-src="https://assets.gofloaters.com/google-play-gofloaters-download.png"
                  className="lazyload"
                  width="150"
                  height="45"
                  alt="GoFloaters Google Play Store"
                />{" "}
              </a>
              &nbsp;
              <a
                href="https://itunes.apple.com/in/app/gofloaters/id1446010118?mt=8&uo=4&at=11l6hc&app=itunes&ct=fnd"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <img
                  data-src="https://assets.gofloaters.com/gofloater-apple-store.png"
                  className="lazyload"
                  width="150"
                  height="45"
                  alt="GoFloaters Apple Store"
                  target="_blank"
                />
              </a>
            </p> */}
          </div>

          {/* <div className="col-md-12 text-center">
            <hr></hr>
            <p>
              <a
                href="https://bit.ly/GoFloatersPlayStore"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img
                  data-src="https://assets.gofloaters.com/google-play-gofloaters-download.png"
                  className="lazyload"
                  width="150"
                  height="45"
                  alt="GoFloaters Google Play Store"
                />{" "}
              </a>
              &nbsp;
              <a
                href="https://itunes.apple.com/in/app/gofloaters/id1446010118?mt=8&uo=4&at=11l6hc&app=itunes&ct=fnd"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                <img
                  data-src="https://assets.gofloaters.com/gofloater-apple-store.png"
                  className="lazyload"
                  width="150"
                  height="45"
                  alt="GoFloaters Apple Store"
                  target="_blank"
                />
              </a>
            </p>
          </div> */}
        </div>
      </div>
      {citymodalView ? (
        <div
          className="modal fade show"
          id="selectCity"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="selectCity"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog " role="document">
            <div className="modal-content" style={{ background: "#fff" }}>
              <div className="modal-body" style={{ padding: 15 }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeCityModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h2
                  style={{
                    fontSize: "1.8em",
                    fontWeight: "bold",
                  }}
                >
                  Popular Cities
                </h2>
                <div className="container">
                  <div className="row">
                    <FamousLocality data={topCity} />
                  </div>
                </div>

                <hr></hr>
                <h2
                  style={{
                    fontSize: "1.8em",
                    fontWeight: "bold",
                  }}
                >
                  Other Cities
                </h2>
                <div className="container">
                  <div className="row">
                    <OtherLocality data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {intentmodalView
        ? ""
        : // <div
          //   className="modal fade show"
          //   id="intentClosed"
          //   tabIndex="-1"
          //   role="dialog"
          //   aria-labelledby="intentClosed"
          //   aria-hidden="true"
          //   style={{ display: "block" }}
          // >
          //   <div className="modal-dialog announcement" role="document">
          //     <div
          //       className="modal-content"
          //       style={{ background: "#fff", textAlign: "center" }}
          //     >
          //       <div className="modal-header">
          //         <button
          //           type="button"
          //           className="close"
          //           data-dismiss="modal"
          //           aria-label="Close"
          //           onClick={intentcloseModal}
          //         >
          //           <span aria-hidden="true">&times;</span>
          //         </button>
          //       </div>
          //       <div className="modal-body" style={{ padding: 15 }}>
          //         <div className="container">
          //           <div className="row  ">
          //             {/* <div className="col-md-3">
          //               <img
          //                 src="https://assets.gofloaters.com/winner-1.png"
          //                 className="img-responsive"
          //               ></img>
          //             </div> */}
          //             <div className="col-md-12">

          //               <h4 style={{ color: "#303030" }}>
          //                 <br />
          //                 <b>Oops! Don't miss that offer we have for you. </b>
          //                 <br />

          //                 Book a space now with discounts<br />
          //                 <br />

          //               </h4>
          //               <a
          //                 style={{
          //                   color: "#303030",
          //                   background: "#f9d470",
          //                   padding: "15px 25px",
          //                   borderRadius: "20px",
          //                   fontSize: "16px",
          //                   fontWeight: "bold",
          //                 }}
          //                 href="#" onClick={()=> { setmodalView(false); setintentmodalView(false);  window.$zoho.salesiq.floatwindow.visible("show") }}>
          //                 Chat Now
          //               </a>
          //             </div>

          //           </div>
          //         </div>

          //         <br />
          //         <br />

          //       </div>
          //     </div>
          //   </div>
          // </div>
          ""}

      <div className={"container " + meetingClass}>
        <div className="row">
          <div className="col-md-12">
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>
              <b
                className="contact-us-p"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                Coworking Day Pass in top cities
              </b>
            </p>

            <CoworkingCityList data={data} />
            <br />
            <br />
          </div>
          <div className="col-md-12">
            <p>
              <b
                className="contact-us-p"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                Meeting Rooms in top cities
              </b>
            </p>
            <MeetingCityList data={data} />
            <br />
            <br />
          </div>
          <div className="col-md-12">
            <p>
              <b
                className="contact-us-p"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                Office Spaces in top cities
              </b>
            </p>{" "}
            <OfficeCityList data={data} />
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2024 GoFloaters</p>
      </div>
      <PrivacyBar />
    </footer>
  )
}

Footer.propTypes = {
  props: PropTypes.object,
}

export default withLocation(Footer)
